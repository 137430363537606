<template>
  <div v-bind="containerProps">
    <div v-bind="wrapperProps">
      <slot v-for="{ data, index } in virtualList" :key="getKey(data)" :data="data" :index="index" name="item"></slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
type Props = {
  items: T[];
  itemHeight: number;
  overscan?: number;
  getKey: (item: T) => string;
};

const props = withDefaults(defineProps<Props>(), {
  overscan: 5,
  getKey: (item: T) => String(item),
});

const { items } = toRefs(props);

const {
  list: virtualList,
  containerProps,
  wrapperProps,
  scrollTo,
} = useVirtualList(items, {
  itemHeight: props.itemHeight,
  overscan: props.overscan,
});

const { y } = useScroll(containerProps.ref);

const scrollPos = ref(0);

watch(items, () => {
  scrollTo(0);
});

watchEffect(() => {
  scrollPos.value = y.value;
});

onActivated(() => {
  scrollTo(scrollPos.value / props.itemHeight);
});
</script>
